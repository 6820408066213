//https://res.cloudinary.com/higher-design/image/upload/v1552703321/people/pete.jpg
import React from 'react';
import {
  View,
  Text,
  FlatList,
  Dimensions,
  Platform,
  StyleSheet,
} from 'react-native';
import HigherGradient from 'components/utility/HigherGradient'
import ProjectCardStrip from 'components/strips/ProjectCardStrip';

//import { LinearGradient } from 'expo';

import PEOPLE from 'constants/people';

export default class AboutSection extends React.Component {

  // Track the window size
  state = Dimensions.get("window");

  onJobPress(job) {
  }

  // Update our dimensions now
  updateDimensions() {
    this.setState(Dimensions.get('window'));
  }

  componentWillMount() {
    if ( Platform.OS == "web" ) {
      window.addEventListener("resize", () => { this.updateDimensions() });
    }
    else {
      Dimensions.addEventListener("change", () => { this.updateDimensions() });
    }
  }

  componentWillUnmount() {
    if ( Platform.OS == "web" ) {
      window.removeEventListener("resize", () => { this.updateDimensions() });
    }
    else {
      Dimensions.removeEventListener("change", () => { this.updateDimensions() });
    }
  }

  // Convert an array of names into an array of projects
  projectByName(projectName) {
    const person = PEOPLE.index[this.props.person];
    if ( person && person.about && person.about.cards && person.about.cards.index ) {
      return person.about.cards.index[projectName];
    }
    else {
      return [];
    }
  }

  render() {

    // Lookup the person
    const person = PEOPLE.index[this.props.person] || {};
    const about = person.about || {};

    // Is it the narrow version
    const narrow = this.state.width < 950;

    // Show a header?
    let header = <View/>;
    if ( this.props.showHeader ) {
      header = <Text style={{ paddingTop: 15, paddingBottom: 15, textAlign: "center", fontSize: 28, fontWeight: "800", fontFamily: 'System'}}>ABOUT</Text>;
    }

    // Optional line
    let line = <View/>;
    if ( this.props.line ) {
      line = <View style={{ height: 1, marginTop: 0, marginBottom: 0, backgroundColor: "#DBDBDB"}}/>;
    }

    // Just make the cards more
    let row = [];
    if ( about && about.cards && about.cards.sequence ) {
      row = about.cards.sequence.map(projectName => { return { ...this.projectByName(projectName), person: this.props.person }; });
    }

/*
    // Return our set of projects
    return <View style={{ width: "100%", backgroundColor: "#f4f4f4" }}>
              <HigherGradient style={{ height: 40, marginBottom: -30 }}
                              direction="to bottom"
                              from={this.props.fadeColor || "#f4f4f4"}
                              to="#f4f4f4"/>
              <ProjectCardStrip cards={row}
                                mobile={this.props.mobile}
                                personName={this.props.person}/>
              <HigherGradient style={{ height: 40, marginTop: -28, zIndex: -1 }}
                              direction="to bottom"
                              from="#f4f4f4"
                              to={this.props.fadeColor || "#f4f4f4"} />
              {line}
           </View>;
*/


    // Show our section
    return (
      <View style={{}}>
        <HigherGradient
           direction="to bottom"
           from="#f4f4f4"
           to="#ffffff">
          {header}
          <View style={styles.description}>
            {about.paragraphs.map((paragraph) => {
              return <Text style={styles.paragraph}>{paragraph}</Text>
            })}

          </View>
        </HigherGradient>
      </View>
    )

    /*        <View style={styles.card}>

    <ProjectCardStrip cards={row}
                      mobile={this.props.mobile}
                      personName={this.props.person}/>

            </View>
*/
  }
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: "white",
    shadowColor: '#000',
    borderRadius:10,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 20,
    elevation: 5,
    paddingTop: 15,
    paddingBottom: 10,
    marginLeft: 20,
    marginRight: 20,
    paddingLeft: 25,
    paddingRight: 35,
  },
  cardContent: {
    width: "100%",
    height: "100%",
  },
  description: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 20,
    marginRight: 20,
    maxWidth: 500,
    textAlign: "left",
    alignSelf: "center",
  },
  paragraph: {
    fontSize: 16,
    fontWeight: "400",
    fontFamily: 'System',
    marginBottom: 15,
  },
});
