import React from 'react';
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
} from 'react-native';
import HigherVideo from 'components/utility/HigherVideo'

export default class TextBladeProject extends React.Component {
  render() {
    return (
      <View>
        <Text style={styles.text}>Lorem ipsum dolor fugis epitat.</Text>
        <HigherVideo style={{ width: "100%", height: "auto" }} useNativeControls source={{ uri: "https://res.cloudinary.com/higher-design/video/upload/v1554355187/videos/textblade-720.mp4" }}/>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  text: {
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 20,
    fontSize: 15,
    fontWeight: "300",
    fontFamily: 'System',
  }
});

/*
<HigherVideo controls src="https://res.cloudinary.com/higher-design/video/upload/v1554355187/videos/textblade-720.mp4"/>
*/
