// src/Home.js
import React from 'react';
import {
  View,
  ScrollView,
  Text,
  FlatList,
  TouchableOpacity,
  Platform,
  Dimensions
} from 'react-native';
import { connect } from 'react-redux'
import Sticky from 'components/utility/Sticky';
import MenuStrip from 'components/strips/MenuStrip';
import CategoryStrip from 'components/strips/CategoryStrip';
import ProjectCardStrip from 'components/strips/ProjectCardStrip';
import ProjectCard from 'components/cards/ProjectCard';
import ActiveCard from 'components/cards/ActiveCard';
import HigherGradient from 'components/utility/HigherGradient';
import Hamburger from 'components/buttons/Hamburger';
import HamburgerOverlay from 'components/overlays/HamburgerOverlay';
import LogoSection from 'components/sections/home/LogoSection';
import PeopleSection from 'components/sections/home/PeopleSection';
import TalkSection from 'components/sections/home/TalkSection';
/*import SkillSection from 'components/sections/SkillSection';
import AboutSection from 'components/sections/AboutSection';*/
import TextSection from 'components/sections/shared/TextSection';
import FooterSection from 'components/sections/shared/FooterSection';
import PortfolioLinkSection from 'components/sections/links/PortfolioLinkSection';
import PeopleLinkSection from 'components/sections/links/PeopleLinkSection';
import SimpleLinkSection from 'components/sections/links/SimpleLinkSection';


import FullScreen from 'components/utility/FullScreen';
import CATEGORIES from 'constants/categories';
import PROJECTS from 'constants/projects';
import MENUS from 'constants/menus';
import FOOTERS from 'constants/footers';
import TEXT from 'constants/text';
import HigherSectionList from 'components/utility/HigherSectionList'
import HigherVideo from 'components/utility/HigherVideo'

import SectionPage from 'components/pages/SectionPage'

import { chooseMenu } from 'state/actions/menuActions.js'
import { chooseCategory } from 'state/actions/categoryActions.js'
import { openHamburger, closeHamburger } from 'state/actions/hamburgerActions.js'

class HomePage extends React.Component {

  // Render the page
  render() {

    // Configure the available sections
    let sections = [
      {title: 'Logo', name:"top", data: ['Logo']},
      {title: 'Categories', name:"portfolio", data: ['Strips']},
      {title: 'Empty', name:"empty", data: ['Empty']},
      /*{title: 'People', name:"people", data: ['People']},*/
      {title: 'Story', name:"story", data: ['Story']},
      {title: 'Approach', name:"approach", data: ['Approach'], lastLineOfDefense: true },
      {title: 'Talk', name:"talk", data: ['Talk'], showLineOnJump: true },
      {title: 'Footer', name:"talk", data: ['Footer'], showLineOnJump: true },
    ];

    // Let the section page do most of the work for us
    return <SectionPage categories={CATEGORIES.sequence}
                        projects={PROJECTS.rows}
                        menu={MENUS.home}
                        description={"We design breakthru experiences."}
                        footer={FOOTERS.home}
                        sections={sections}
                        history={this.props.history}
                        renderSection={(section) => { return this.renderSection(section); }}/>
  }
}

export default HomePage;

/*  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {
  }

  onHamburger() {
    if ( this.props.hamburger.active ) {
      this.props.closeHamburger();
    }
    else {
      this.props.openHamburger();
      setTimeout(() => { this.props.chooseCategory(undefined); }, 300);
    }
  }

  gotoHamburgerLink(name) {
    this.props.closeHamburger();
    this.gotoSectionWithName(name);
    this.highlightSection({ name: name });
  }

  showCategory(category) {
    // Make sure to show top of portfolio cards
    this.gotoSectionWithName("portfolio");

    // Force scrollist refresh
    this._mainList.forceRefresh();
  }

  gotoSectionWithName(name){
    if (this._mainList) {
      this._mainList.scrollToSectionWithName(name);
    }
  }

  highlightSection(section) {
    console.log("highlightSection:",section);
    this.props.chooseMenu(MENUS.home.index[section.menu || section.name]);
  }

  render() {

    // Create our content
    const content = <View>
            <View style={{ height: 1000 }}>
            </View>
            <View className="stickyStop" style={{ height: 1 }}>
            </View>
            <View style={{ height: 2000 }}>
            </View>
          </View>;

    // Which cards to show?
    let row1 = PROJECTS.rows[0].map(projectName => PROJECTS.index[projectName]);
    let row2 = PROJECTS.rows[1].map(projectName => PROJECTS.index[projectName]);
    let cards = [...row1, ...row2];
    let cardStrips = <View/>;
    if ( this.props.selectedCategoryName ) {
      cards = cards.filter( card => card.categories.includes(this.props.selectedCategoryName) )
      cardStrips =
                   <View style={{ width: "100%", backgroundColor: "#f4f4f4" }}>
                      <HigherGradient style={{ height: 40, marginBottom: -20 }} direction="to bottom" from="#ffffff" to="#f4f4f4"/>
                      <ProjectCardStrip history={this.props.history} cards={cards}/>
                      <HigherGradient style={{ height: 40, marginTop: -28, zIndex: -1 }} direction="to bottom" from="#f4f4f4" to="#ffffff"/>
                      <View style={{ height: 1, marginTop: 0, marginBottom: 0, backgroundColor: "#DBDBDB"}}/>
                   </View>
    }
    else {
       cardStrips =  <View style={{ width: "100%", backgroundColor: "#f4f4f4" }}>
                        <HigherGradient style={{ height: 40, marginBottom: -30 }} direction="to bottom" from="#ffffff" to="#f4f4f4"/>
                        <ProjectCardStrip history={this.props.history} cards={row1}/>
                        <ProjectCardStrip history={this.props.history} cards={row2}/>
                        <HigherGradient style={{ height: 40, marginTop: -28, zIndex: -1 }} direction="to bottom" from="#f4f4f4" to="#ffffff"/>
                        <View style={{ height: 1, marginTop: 0, marginBottom: 0, backgroundColor: "#DBDBDB"}}/>
                      </View>;
    }

    // Configure the available sections
    let sections = [
      {title: 'Logo', name:"top", data: ['Logo']},
      {title: 'Categories', name:"portfolio", data: ['Strips']},
      {title: 'People', name:"people", data: ['People']},
      {title: 'Approach', name:"approach", data: ['Approach']},
      {title: 'Talk', name:"talk", data: ['Talk']},
      {title: 'Footer', name:"talk", data: ['Footer']},
    ];

    //             <SimpleLinkSection title="Approach" line onPress={() => { this.gotoHamburgerLink("approach") }}/>


    // Native version
    return (
      <FullScreen>
        <View style={{ height: "100%", marginTop: 0, width: "100%", backgroundColor: "#f4f4f4"}}>
          <HamburgerOverlay active={this.props.hamburger.active}>
            <PortfolioLinkSection onImagePress={() => { this.gotoHamburgerLink("top") }}
                                  onPortfolioPress={() => { this.gotoHamburgerLink("portfolio") }}/>
            <CategoryStrip background="#ffffff" categories={CATEGORIES.sequence} onCategoryPress={(category)=>{this.showCategory(category);}}/>
            <View style={{ height: 1, marginTop: 10, backgroundColor: "#DBDBDB"}}/>
            <PeopleLinkSection title="People" line onPress={() => { this.gotoHamburgerLink("people") }}/>
            <SimpleLinkSection title="Story" line onPress={() => { this.gotoHamburgerLink("story") }}/>
            <SimpleLinkSection title="Talk" onPress={() => { this.gotoHamburgerLink("talk") }}/>
          </HamburgerOverlay>
          <Hamburger active={this.props.hamburger.active} style={{ position: "absolute", left: 20, top: 12, zIndex: 100 }} width={25} height={25} color="#C0BFBF" onPress={() => { this.onHamburger(); }}/>
          <MenuStrip ref={component => this._mainMenu = component} menu={MENUS.home} onMenuPress={(menu)=>{this.gotoSectionWithName(menu.name)}}/>
          <HigherSectionList style={{ width:"100%"}}
            stickySectionHeadersEnabled={true}
            ref={component => this._mainList = component}
            onSectionChange={(section)=>{this.highlightSection(section)}}
            renderSectionHeader={({section: {title}}) =>{
              switch (title) {
                case "Categories":
                  return <CategoryStrip line categories={CATEGORIES.sequence} onCategoryPress={(category)=>{this.showCategory(category);}}/>;
                case "People":
                  return <Text style={{ paddingTop: 25, paddingBottom: 15, textAlign: "center", backgroundColor: "#ffffff", fontSize: 28, fontWeight: "800", fontFamily: 'System'}}>{title.toUpperCase()}</Text>
                case "Story":
                case "Approach":
                  return <Text style={{ paddingTop: 15, paddingBottom: 15, textAlign: "center", backgroundColor: "#ffffff", fontSize: 28, fontWeight: "800", fontFamily: 'System'}}>{title.toUpperCase()}</Text>
                case "Talk":
                  return <Text style={{ paddingTop: 15, paddingBottom: 15, textAlign: "center", backgroundColor: "#ffffff", fontSize: 28, fontWeight: "800", fontFamily: 'System'}}>{title.toUpperCase()}</Text>
                case "Logo":
                case "Footer":
                  return <View/>;
                default:
                  return <Text style={{fontSize: "28px", width:"100%",textAlign:"center"}}>{title.toUpperCase()}</Text>
              }
            }}
            renderItem={({item, index, section}) => {
              let key = `${section.title}:${item}`;
              switch (key) {
                case "Logo:Logo":
                  return (
                    <View key={index}>
                      <LogoSection history={this.props.history}/>
                    </View>
                  )
                  break;
                case "Categories:Strips":
                  return cardStrips
                  break;
                case "Story:Story":
                  return (
                    <View key={index}>
                      <TextSection history={this.props.history} paragraphs={TEXT.story.paragraphs}/>
                    </View>
                  )
                  break;
                case "Approach:Approach":
                  return (
                    <View key={index}>
                      <TextSection history={this.props.history} paragraphs={TEXT.approach.paragraphs}/>
                    </View>
                  )
                  break;
                case "People:People":
                  return (
                    <View key={index}>
                      <PeopleSection history={this.props.history} description="Passion meet precision.  Veteran 10xers."/>
                      <View style={{ backgroundColor: "#f4f4f4", height: 0, width: "100%", flex: 1 }}/>
                    </View>
                  )
                  break;
                case "Talk:Talk":
                  return (
                    <View key={index}>
                      <TalkSection history={this.props.history}/>
                      <View style={{ backgroundColor: "#f4f4f4", height: 0, width: "100%", flex: 1 }}/>
                    </View>
                  )
                  break;
                case "Footer:Footer":
                  return <FooterSection footer={FOOTERS.home} history={this.props.history}/>
                  break;
                default:
                  return <Text key={index}>{item}</Text>
              }
            }}
            sections={sections}
            keyExtractor={(item, index) => item + index}
          />
          <ActiveCard history={this.props.history}/>


        </View>
      </FullScreen>
    );

  }
};

/*

<Sticky bottomBoundary=".stickyStop">
</Sticky>
*/
/*
const childProps = (state, parentProps) => ({
  ...parentProps,
  selectedCategoryName: state.category,
  activeCard: state.card,
  hamburger: state.hamburger,
});

const childDispatch = (dispatch) => {
    return {
      chooseMenu: (menu) => dispatch(chooseMenu(menu)),
      chooseCategory: (category) => dispatch(chooseCategory(category)),
      openHamburger: (hamburger) => dispatch(openHamburger(hamburger)),
      closeHamburger: (hamburger) => dispatch(closeHamburger(hamburger)),
    };
};

export default connect(
  childProps,
  childDispatch
)(HomePage);

*/
