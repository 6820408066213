// src/Home.js
import React from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import JobBulletSet from 'components/items/JobBulletSet';
import { connect } from 'react-redux'
//import { Haptic } from 'expo';

class JobItem extends React.Component {

  // Used for animations on press
  state = {
    pressed: false,
  }

  componentDidMount() {
  }

  // Auto scroll to bottom after extending
  componentDidUpdate(prevProps, prevState) {
  }

  onJobPress() {
  }

  render() {

    // Go blank if we're supposed to hide this item
    if ( this.props.narrow && !this.props.expanded && this.props.job.hideWhenCollapsed ) {
      return <View/>;
    }
    else {

      // Switch to the narrow styles if we need to
      let styles = wideStyles;
      if ( this.props.narrow ) {
        styles = narrowStyles;
      }

      let where = <View/>;
      if ( this.props.job.where ) {
        where = <Text style={styles.where}>{this.props.job.where}</Text>;
      }

      let description = <View/>;
      if ( !this.props.narrow || this.props.expanded ) {
        if ( this.props.job.description ) {
          description = <Text style={styles.description}>{this.props.job.description}</Text>;
        }
      }

      // Get our bullets together
      let bullets = <View/>;
      if ( !this.props.narrow || this.props.expanded ) {
         bullets = <View>
                      {this.props.job.bullets.map((set) => {
                        return <JobBulletSet narrow={this.props.narrow} bullets={set}/>;
                      })}
                    </View>;
      }

      // Return it now
      if ( this.props.narrow ) {

        let role = <Text style={styles.role}>{this.props.job.role}</Text>;
        if ( this.props.job.narrowRole ) {
          role = <Text style={styles.role}>{this.props.job.narrowRole}</Text>;
        }

        return <View style={styles.job}>
         <View style={styles.column2}>
            <View style={styles.header}>
            <Text style={styles.when}>{this.props.job.when}</Text>
              <Text style={styles.project}>{this.props.job.project}</Text>
              {role}
            </View>
            {description}
            {bullets}
         </View>
        </View>;
      }
      else {
        return (
        <View style={styles.job}>
         <View style={styles.column1}>
           {where}
           <Text style={styles.when}>{this.props.job.when}</Text>
         </View>
         <View style={styles.column2}>
            <View style={styles.header}>
              <Text style={styles.project}>{this.props.job.project}</Text>
              <Text style={styles.role}>{this.props.job.role}</Text>
            </View>
            {description}
            {bullets}
         </View>
        </View>
        );
      }
    }
  }
};

const childProps = (state, parentProps) => ({
  ...parentProps,
});

const childDispatch = (dispatch) => {
    return {
    };
};

export default connect(
  childProps,
  childDispatch
)(JobItem);

const wideStyles = StyleSheet.create({
  job: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    marginLeft: 15,
    marginRight: 15,
    marginTop: 15,
    marginBottom: 10,
  },
  column1: {
    alignSelf: "flex-start",
    textAlign: "left",
    marginLeft: 10,
    width: 130
  },
  column2: {
    flex: 1,
    alignSelf: "flex-start",
    marginLeft: 10,
    textAlign: "left"
  },
  where: {
    fontSize: 18,
    fontWeight: "700",
    fontFamily: 'System',
    color: "#00AEEF",
    textTransform: "uppercase",
  },
  when: {
    color: "#888888",
    fontSize: 15,
    fontWeight: "400",
    fontFamily: 'System',
    marginTop: 4,
    marginBottom: 5,
  },
  header: {
    flex: 1,
    flexDirection: "row"
  },
  project: {
    fontSize: 20,
    fontWeight: "600",
    fontFamily: 'System',
    color: "black",
    lineHeight: 20
  },
  role: {
    textTransform: "uppercase",
    color: "#00AEEF",
    color: "#21D078",
    color: "#777777",
    color: "#00AEEF",
    fontSize: 16,
    fontWeight: "600",
    fontFamily: 'System',
    marginLeft: 8,
    lineHeight: 20,
  },
  description: {
    marginTop: 5,
    marginBottom: 5,
    fontSize: 15,
    fontWeight: "400",
    fontFamily: 'System',
  }
});


const narrowStyles = StyleSheet.create({
  job: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 15,
  },
  column1: {
    textAlign: "left",
    marginLeft: 10,
    width: 130
  },
  column2: {
    flex: 1,
    marginLeft: 10,
    textAlign: "left"
  },
  where: {
    fontSize: 22,
    fontWeight: "700",
    fontFamily: 'System',
    color: "#00AEEF",
    marginBottom: 5,
  },
  header: {
  },
  project: {
    fontSize: 18,
    fontWeight: "600",
    fontFamily: 'System',
    color: "black",
    lineHeight: 20
  },
  role: {
    color: "black",
    fontSize: 16,
    fontWeight: "400",
    fontFamily: 'System',
    lineHeight: 20,
    marginTop: 4,
    marginBottom: 10,
    /*paddingBottom: 10,
    borderBottom: "1px solid #DDDDDD"*/
  },
  when: {
    color: "#777777",
    fontSize: 16,
    fontWeight: "400",
    fontFamily: 'System',
    marginTop: 2,
    marginBottom: 6,
  },
  description: {
    marginTop: 5,
    marginBottom: 5,
    fontSize: 15,
    fontWeight: "400",
    fontFamily: 'System',
  }
});
