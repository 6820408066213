//https://res.cloudinary.com/higher-design/image/upload/v1552703321/people/pete.jpg
import React from 'react';
import {
  View,
  Image,
  Text,
  TouchableOpacity,
} from 'react-native';
import HigherGradient from 'components/utility/HigherGradient'
import PEOPLE from 'constants/people';

export default class PortfolioLinkSection extends React.Component {

  onImagePress() {
    if ( this.props.onImagePress ) {
      this.props.onImagePress();
    }
  }

  onPortfolioPress() {
    if ( this.props.onPortfolioPress ) {
      this.props.onPortfolioPress();
    }
  }

  render() {

    // Is there a person here?
    if ( this.props.person ) {

      // Lookup the person
      const person = PEOPLE.index[this.props.person] || {};

      // This is the image for the person
      const personImage = `https://res.cloudinary.com/higher-design/image/upload/r_max,h_256,w_256/v1551630385/people/${person.image}.jpg`;

      // Show our section
      return (
        <HigherGradient
           direction="to bottom"
           from="#ffffff"
           to="#ffffff">
          <View style={{  textAlign: "center" }}>
              <TouchableOpacity style={{ paddingTop: 20, paddingBottom: 7 }} onPress={() => { this.onImagePress(); }}>
                <Image style={{ width: 80, height: 80, alignSelf: "center", borderRadius: 80 }} source={{ uri: personImage }}/>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => { this.onPortfolioPress(); }}>
                <Text style={{ paddingTop: 8, paddingBottom: 10, fontSize: 28, fontWeight: "800", fontFamily: 'System' }}>PORTFOLIO</Text>
                <Text style={{ marginBottom: 15, fontSize: 16, fontWeight: "400", fontFamily: 'System' }}>{person.shortDescription}</Text>
              </TouchableOpacity>
          </View>
        </HigherGradient>
      )
    }
    else {

      // This is the image for the Higher design logo
      const logoImage = `https://res.cloudinary.com/higher-design/image/upload/w_256/v1551630385/logos/higher.png`;

      // Show our section
      return (
        <HigherGradient
           direction="to bottom"
           from="#ffffff"
           to="#ffffff">
          <View style={{  textAlign: "center" }}>
              <TouchableOpacity style={{ paddingTop: 20, paddingBottom: 7 }} onPress={() => { this.onImagePress(); }}>
                <Image style={{ width: 60, height: 60, alignSelf: "center", resizeMode: "contain" }} source={{ uri: logoImage }}/>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => { this.onPortfolioPress(); }}>
                <Text style={{ paddingTop: 8, paddingBottom: 10, fontSize: 28, fontWeight: "800", fontFamily: 'System' }}>PORTFOLIO</Text>
                <Text style={{ marginBottom: 15, fontSize: 16, fontWeight: "400", fontFamily: 'System' }}>Full spectrum design.</Text>
              </TouchableOpacity>
          </View>
        </HigherGradient>
      )
    }
  }
}
