//https://res.cloudinary.com/higher-design/image/upload/v1552703321/people/pete.jpg
import React from 'react';
import {
  View,
  Text,
  Image,
  FlatList,
  Dimensions,
  Platform,
  StyleSheet,
} from 'react-native';
import PEOPLE from 'constants/people';
import ConnectButton from 'components/buttons/ConnectButton';

export default class TalkSection extends React.Component {

  // Track the window size
  state = Dimensions.get("window");

  // Update our dimensions now
  updateDimensions() {
    this.setState(Dimensions.get('window'));
  }

  componentWillMount() {
    if ( Platform.OS == "web" ) {
      window.addEventListener("resize", () => { this.updateDimensions() });
    }
    else {
      Dimensions.addEventListener("change", () => { this.updateDimensions() });
    }
  }

  componentWillUnmount() {
    if ( Platform.OS == "web" ) {
      window.removeEventListener("resize", () => { this.updateDimensions() });
    }
    else {
      Dimensions.removeEventListener("change", () => { this.updateDimensions() });
    }
  }

  render() {

    // This is the image for the person
    const logoImage = `https://res.cloudinary.com/higher-design/image/upload/w_256/v1551630385/logos/higher.png`;

    // Show a header?
    let header = <View/>;
    if ( this.props.showHeader ) {
      header = <Text style={{ paddingTop: 15, paddingBottom: 15, textAlign: "center", fontSize: 28, fontWeight: "800", fontFamily: 'System'}}>TALK</Text>;
    }

    // Is it the narrow version
    const narrow = this.state.width < 950;

    // Figure out the button situation
    let buttons = <View/>;
    if ( narrow ) {
      buttons = <View style={styles.buttonsNarrow}>
        <View style={styles.button}><ConnectButton text="Let's Connect!" email="hi@higher.design" width={310} backgroundColor="#21D078"/></View>
      </View>;
    }
    else {
      buttons = <View style={styles.buttonsWide}>
        <View style={styles.button}><ConnectButton text="Let's Connect!" email="hi@higher.design" width={310} backgroundColor="#21D078"/></View>
      </View>;
    }

    // Show our section
    return (
      <View style={{ backgroundColor: "#ffffff", textAlign: "center", paddingBottom: 15 }}>
        {header}
        <View style={styles.description}>
          <Text style={styles.paragraph}>We <b>love</b> brave new ideas, and throwing all of our creativity and passion into bringing them to life.</Text>
          <Image style={{ width: 100, height: 100, marginTop: 20, marginBottom: 30, alignSelf: "center"}} resizeMode="contain" source={{ uri: logoImage }}/>
          <Text style={styles.paragraph}>We work with select tech companies to craft <b>amazing</b> experiences for their users.</Text>
        </View>
        {buttons}
      </View>
    )
  }
}

/*

<Text style={styles.paragraph}>Do you want to create an <b>amazing</b> user experience
and break new ground in your target market? </Text>
*/

const styles = StyleSheet.create({
  description: {
    marginTop: 0,
    marginBottom: 25,
    marginLeft: 35,
    marginRight: 35,
    maxWidth: 310,
    textAlign: "left",
    alignSelf: "center",
    justifyContent: "center",
  },
  paragraph: {
    fontSize: 16,
    fontWeight: "400",
    fontFamily: 'System',
  },
  buttonsWide: {
    margin: "0 auto",
    alignSelf: "center",
    justifyContent: "center",
    textAlign: "center",
    marginTop: 5,
    marginBottom: 10,
  },
  buttonsNarrow: {
    margin: "0 auto",
    alignSelf: "center",
    justifyContent: "center",
    textAlign: "center",
    marginTop: 5,
    marginBottom: 10,
  },
  buttonSeparator: {
    width: 15,
  },
  button: {
    flex: 1,
  },
});
