/*https://res.cloudinary.com/higher-design/image/upload/v1551630385/categories/*/

import React from 'react';
import TextBladeProject from 'components/projects/TextBladeProject';

import UPGIFT from "constants/projects/upgift"
import WAYTOOLS from "constants/projects/waytools"
import NEXTENGINE from "constants/projects/nextengine"
import PERSONAL from "constants/projects/personal"
import STORYJAR from "constants/projects/storyjar"
import MAGBAK from "constants/projects/magbak"
import TESLA from "constants/projects/tesla"
import DAQRI from "constants/projects/daqri"
import BLUE from "constants/projects/blue"
import FUTURE from "constants/projects/future"

export default {
    index: {
      ...UPGIFT,
      ...WAYTOOLS,
      ...NEXTENGINE,
      ...PERSONAL,
      ...STORYJAR,
      ...MAGBAK,
      ...TESLA,
      ...DAQRI,
      ...BLUE,
      ...FUTURE,
    },
    rows: [["textblade-keyboard", "textblade-app", "textblade-order-system", "textblade-patent", "keyswitch-patent", "separable-keyboard-patent", "textblade-design-patent", "textblade-cursor-patent", "textblade-modkeys-patent", "textblade-international-layouts", "fingerbot", "forcebot", "textblade-website", "remote-scopes",  "realtime-device-sync", "textblade-video", "hands-on-video", "universal-keyboard-video", "multimap-video", "android-video", ],
           ["nextengine-scanner", "scanstudio", "nextengine-comparo", "handheld-scanner-patent", "photo-chat-patent", "integrated-capture-patent", "shape-search-patent", "nextengine-website", "nextengine-order-system", "nextwiki", "rapidworks", ],
           ["magbak-case", "magbak-wallet", "magbak-charger", "magbak-patent-self-align", "magbak-patent-low-profile"],
           ["upgift-app", "upgift-website", "upgift-video", "upgift-payments", "upgift-social-network", "upgift-brand"],
           ["model3", "blue", "daqri", "teamfolio"],
          ]
}
