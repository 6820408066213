import RESUME from './pete/resume'

export default {
  name: "pete",
  image: "pete-front-bw-bright",
  /*image: "pete-front2",*/
  /*image: "pete-looking6",*/
  url: "https://petedelaurentis.com",
  firstName: "Pete",
  lastName: "DeLaurentis",
  title: "Product Designer",
  resumeTitle: "DESIGN + ENGINEERING",
  categories: ["app", "video", "hardware", "web", "cloud"],
  projects: [[/*"photo-chat-patent",*/ "upgift-app", "upgift-website", "upgift-video", "upgift-payments", "upgift-social-network", "upgift-search", "upgift-brand", "give-experiences-ads", "santa-ads", "meme-ads"],
             ["textblade-keyboard", "textblade-app", "textblade-website", "textblade-order-system",  "remote-scopes",  "realtime-device-sync", "textblade-video", "hands-on-video", "universal-keyboard-video", "multimap-video", "android-video", "textblade-international-layouts", "fingerbot", "forcebot", "textblade-patent", "textblade-design-patent", "textblade-cursor-patent", "textblade-modkeys-patent"],
             ["scanstudio", "nextengine-scanner", "nextengine-comparo", "nextengine-website", "portfolio", "storyjar", "rapidworks", "nextengine-order-system", "nextwiki", "nextengine-licensing", "handheld-scanner-patent", "photo-chat-patent", "integrated-capture-patent", "shape-search-patent"],
           ],
  menu: {
    index: {
      top: {
        name: "top",
        title: "PETE"
      },
      portfolio: {
        name: "portfolio",
        title: "PORTFOLIO",
        darken: true,
      },
      cv: {
        name: "cv",
        title: "CV",
        darken: true,
      },
      skills: {
        name: "skills",
        title: "SKILLS",
        darken: true,
      },
      about: {
        name: "about",
        title: "ABOUT",
        darken: true,
      },
      talk: {
        name: "talk",
        title: "TALK",
        darken: true,
      },
    },
    sequence: ["top", "portfolio", "cv", "skills", "about", "talk"]
  },
  footer: {
    columnWidth: 250,
    index: {
      copyright: {
          symbol: "copyright",
          text: "2019 Peter DeLaurentis",
      },
      location: {
          symbol: "location",
          text: "San Francisco, CA",
      },
      dribbble: {
          symbol: "linkedin",
          url: "https://www.linkedin.com/in/pete-delaurentis-3622192/",
          text: "Connect on LinkedIn",
      },
      email: {
          symbol: "email",
          url: "mailto:pete.delaurentis@gmail.com",
          text: "pete.delaurentis@gmail.com",
      },

    },
    sequence: ["copyright", "location", "dribbble", "email"]
  },
  shortDescription: "Full spectrum design.",
  /*description: "Full spectrum design.  I empower users\nwith simple tools that do amazing things.",
  description: "Full spectrum design.  I bring new capabilities to the mainstream with the art of radical simplicity.",
  description: "Full spectrum design.  Simplicity is the key\nthat unlocks new capabilities for the mainstream.",
  description: "Full spectrum design.  Simplicity unlocks new capabilities for the mainstream.",
  description: "Full spectrum design.  Capability and simplicity\nare not mutually exclusive.",
  description: "Full spectrum design.  I empower users with\nnew capabilities through the art of simplicity.",
  description: "Full spectrum design.  I bring new tech\nto the mainstream with the art of simplicity.",
  description: "Full spectrum design.  Simple on the outside.  Breakthrough technology on the inside.",
  description: "Full spectrum design.  I bring breakthrough\ntech to the mainstream via radical simplicity.",
  description: "Full spectrum design.  I empower users with\nnew technology through the art of simplicity.",*/
  /*description: "Full spectrum design.  I build simple\ntools with breakthrough capabilities.",*/
  /*description: "I lead the design of effortless products that\nempowers users with breakthru capabilities.",*/
  /*description: "I lead the design of breakthrough products\nthat empower users through simplicity.",*/
  /*description: "I lead the creation of experiences that alloy\nsimple design with breakthrough capabilities.",*/
  //description: "I lead the creation of products that unite\nsimplicity with breakthrough capability.",
/*  description: "I lead the creation of breakthrough products\nthat maximize both simplicity and capability.",
  description: "I design breakthrough products that\ndeliver sophsiticated capabilities in a simple package.",*/
  /*description: "I make simple and novel user experiences\nthat deliver sophisticated capabilities.\n",*/

  /*description: "I create simple and novel user experiences\nthat deliver surprisingly powerful capabilities.",*/
  description: "Designer, developer, and leader.  Creator of\nbrave new products and $100M+ in revenue.",

  /*  description: "I design products that empower users\nwith simplicity, empathy, and capability.",
*/
  //description: "I design breakthrough products\nthat empower users through simplicity.",
  //description: "I lead the design of products that unite\nsimplicity with breakthrough capability.",
  /*description: "I lead the creation of products that unlock\nbreakthrough capability via the art of simplicity.",*/
/*
  description: "Full spectrum design.  Simplicity empowers\nus with new capabilities.",
  /*Natural, fluid designs that make everyone a power user.*/
  /*Simple, elegant\nexperiences that empower users with new capabilities.*/
  about: {
    paragraphs: [
      /*"I love creating brave new products that enable cultural shifts and help us move into deeper harmony with each other and our planet.",*/
      "I love paving new territory, and helping lead the creation of products that solve meaningful problems.  When technology is paired with insight and empathy for the needs of others, really great things can happen.",
      "I'm grateful to have had multiple opportunities in my career to make a meaningful contribution: reducing the load on our planet and helping people experience more life and less clutter (UpGift), helping people to express themselves fully wherever they are (TextBlade), democratizing the creation of 3D content and allowing for more efficient manufacturing (NextEngine).",
      "I believe that design and engineering are practices that work best when all ideas are welcome, and all viewpoints are honored.  Insight can come from anywhere, and every single person, situation, and interaction can be a powerful teacher – if we listen.",
      "When I'm not working, I love playing, cooking, writing stories, and making art with my 3 amazing kids.  I'm a lifelong student of meditation, and believe that inner work we do and gratitude we cultivate forms the foundation for our outer lives.",
    ],
    cards: {
      index: {
        "unicorn": {
          type: "about",
          name: "unicorn",
          image: "about/electric-unicorn",
          image: "about/unicorn-white-background",
          /*thumb: { zoom: 3.5, resolution: 500, x: 1, y: 0 },*/
          theme: "light",
          aspectRatio: 0.55,
          /*aspectRatio: 0.40,*/
          title: "Design + Engineering",
          subtitle: "Hardware",
          categories: ["hardware"],
          description: "Full QWERTY keyboard magnetically self-packs to 1/3rd size of iPhone. 101 keys are replaced by one smart key per finger.   Keys are multi-touch surfaces with luxurious key travel.",
          /*Full QWERTY keyboard, packs to 1/3rd size of iPhone.  Magnetic suspension +  luxurious key feel.*/
          content: [
            { text: "TextBlade is a full QWERTY keyboard that magnetically self-packs to 1/3rd size of iPhone. 101 keys are replaced by one smart key per finger. Each key features a MultiTouch surface which detects the position of your finger, and a magnetic suspension for luxurious key feel.", },
          ],
        }
      },
      sequence: ["unicorn"],
    }
  },
  talk: {
    email: "pete@up.gift",
    button: "Yes, Let's Connect!",
    paragraphs: [
      "Want to explore working together?"
    ],
  },
  jobs: RESUME.jobs,
  skills: RESUME.skills,
};
