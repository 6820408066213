// src/Home.js
import React from 'react';
import {
  View,
  ScrollView,
  Text,
  FlatList,
  TouchableOpacity,
  Platform,
  Easing,
  Animated,
  Dimensions
} from 'react-native';
import { connect } from 'react-redux';
import SectionPage from 'components/pages/SectionPage';
import PEOPLE from 'constants/people';
import PROJECTS from 'constants/projects';
import ReactDOM from 'react-dom';

class PersonPage extends React.Component {

  // Set the title when we first show the page
  componentWillMount() {
    document.title = this.documentTitle();
  }

  // Return the proper title
  documentTitle() {
    const person = PEOPLE.index[this.props.personName] || {};
    if ( person ) {
      return `${person.firstName} ${person.lastName}`;
    }
    else {
      return "Pete DeLaurentis";
    }
  }

  // Render the page
  render() {

    // Lookup the person
    const person = PEOPLE.index[this.props.personName] || {};

    // Configure the available sections
    let sections = [
      {title: 'Person', name:"top", data: ['Person']},
      {title: 'Categories', name:"portfolio", data: ['Strips']},
      {title: 'Empty', name:"empty", data: ['Empty']},
      {title: 'CV', name:"cv", data: ['CV']},
      {title: 'Skills', name:"skills", data: ['Skills']},
      {title: 'About', name:"about", data: ['About'], lastLineOfDefense: true },
      {title: 'Talk', name:"talk", data: ['Talk'], showLineOnJump: true },
      {title: 'Footer', name:"talk", data: ['Footer'], showLineOnJump: true },
    ];

    // Let the section page do most of the work for us
    return <SectionPage personName={this.props.personName}
                        categories={person.categories}
                        projects={person.projects}
                        menu={person.menu}
                        description={person.description}
                        footer={person.footer}
                        sections={sections}
                        history={this.props.history}
                        renderSection={(section) => { return this.renderSection(section); }}/>
  }
};

export default PersonPage;
