//https://res.cloudinary.com/higher-design/image/upload/v1552703321/people/pete.jpg
import React from 'react';
import {
  View,
  Text,
  FlatList,
  Dimensions,
  Platform,
  StyleSheet,
} from 'react-native';
import JobItem from 'components/items/JobItem';
import ViewResumeButton from 'components/buttons/ViewResumeButton';
import DownloadResumeButton from 'components/buttons/DownloadResumeButton';
import ResumeCard from 'components/cards/ResumeCard';
import PEOPLE from 'constants/people';

export default class ResumeSection extends React.Component {

  // Track the window size
  state = Dimensions.get("window");

  onJobPress(job) {

  }

  // Update our dimensions now
  updateDimensions() {
    this.setState(Dimensions.get('window'));
  }

  componentWillMount() {
    if ( Platform.OS == "web" ) {
      window.addEventListener("resize", () => { this.updateDimensions() });
    }
    else {
      Dimensions.addEventListener("change", () => { this.updateDimensions() });
    }
  }

  componentWillUnmount() {
    if ( Platform.OS == "web" ) {
      window.removeEventListener("resize", () => { this.updateDimensions() });
    }
    else {
      Dimensions.removeEventListener("change", () => { this.updateDimensions() });
    }
  }

  render() {

    // Lookup the person
    const resume = PEOPLE.index[this.props.person] || {};
    const jobs = resume.jobs || { index: {}, sequence: [] };

    // Is it the narrow version
    const narrow = this.state.width < 950;

    let buttons = <View/>;
    if ( narrow ) {
      buttons = <View style={styles.buttonsNarrow}>
                  <View style={styles.button}><ViewResumeButton backgroundColor="#21D078"/></View>
                  <View style={styles.buttonSeparator}/>
                  <View style={styles.button}><DownloadResumeButton person={this.props.person} backgroundColor="#00AEEF"/></View>
                </View>
    }
    else {
      //FF2187
      buttons = <View style={styles.buttonsWide}>
                  <View style={styles.button}><DownloadResumeButton person={this.props.person} text="Download" width={220} backgroundColor="#00AEEF"/></View>
                </View>
    }

    // Show a header?
    let header = <View/>;
    if ( this.props.showHeader ) {
      header = <Text style={{ paddingTop: 15, paddingBottom: 15, textAlign: "center", fontSize: 28, fontWeight: "800", fontFamily: 'System'}}>CV</Text>;
    }

    // Show our section
    return (
      <View style={{ backgroundColor: this.props.background || "#f4f4f4", textAlign: "center" }}>
        {header}
        <Text style={{ marginTop: 0, marginBottom: 0, fontSize: 16, fontWeight: "400", fontFamily: 'System' }}>{jobs.description}</Text>
        <ResumeCard person={resume}
                    fullScreen={narrow}
                    radius={10}>
        </ResumeCard>
      </View>
    )
  }
}

/*
<View style={[styles.card, { paddingBottom: narrow ? 0 : 15 }]}>
  <FlatList
    style={{  }}
    keyExtractor={job => job.name}
    data={jobs.sequence.map(jobName => jobs.index[jobName])}
    renderItem={({ item }) => (
      <View><JobItem job={item} narrow={narrow} onPress={()=>{ this.onJobPress(item); }}/></View>
    )}
  />
  {buttons}
</View>
*/

const styles = StyleSheet.create({
  card: {
    backgroundColor: "white",
    shadowColor: '#000',
    borderRadius:10,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 20,
    elevation: 5,
    paddingTop: 10,
    marginLeft: 20,
    marginRight: 20
  },
  cardContent: {
    width: "100%",
    height: "100%",
  },
  buttonsWide: {
    margin: "0 auto",
    alignSelf: "center",
    justifyContent: "center",
    textAlign: "center",
    marginTop: 20,
    marginBottom: 10,
  },
  buttonsNarrow: {
    flexDirection: "row",
    justifyContent: "center",
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10,
    marginBottom: 20,
    height: 50,
    maxWidth: 300
  },
  buttonSeparator: {
    width: 15,
  },
  button: {
    flex: 1,
  }
});
