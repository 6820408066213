//https://res.cloudinary.com/higher-design/image/upload/v1552703321/people/pete.jpg
import React from 'react';
import {
  View,
  Text,
  FlatList,
  Dimensions,
  Platform,
  StyleSheet,
} from 'react-native';
import SkillSet from 'components/items/SkillSet';
import PEOPLE from 'constants/people';

export default class SkillSection extends React.Component {

  // Track the window size
  state = Dimensions.get("window");

  onJobPress(job) {
  }

  // Update our dimensions now
  updateDimensions() {
    this.setState(Dimensions.get('window'));
  }

  componentWillMount() {
    if ( Platform.OS == "web" ) {
      window.addEventListener("resize", () => { this.updateDimensions() });
    }
    else {
      Dimensions.addEventListener("change", () => { this.updateDimensions() });
    }
  }

  componentWillUnmount() {
    if ( Platform.OS == "web" ) {
      window.removeEventListener("resize", () => { this.updateDimensions() });
    }
    else {
      Dimensions.removeEventListener("change", () => { this.updateDimensions() });
    }
  }

  render() {

    // Lookup the person
    const resume = PEOPLE.index[this.props.person] || {};
    const skills = resume.skills || { index: {}, sequence: [] };

    // Is it the narrow version
    const narrow = this.state.width < 500;
    const medium = !narrow && this.state.width < 850;
    const narrowMedium = this.state.width > 500 && this.state.width < 700;

    // Show a header?
    let header = <View/>;
    if ( this.props.showHeader ) {
      header = <Text style={{ paddingTop: 15, paddingBottom: 15, textAlign: "center", fontSize: 28, fontWeight: "800", fontFamily: 'System'}}>SKILLS</Text>;
    }

    let list = <View/>;
    if ( medium ) {
      list = <View style={styles.card}>
                <FlatList
                          contentContainerStyle={ styles.mediumList }
                          horizontal={true}
                          keyExtractor={skill => skill.name}
                          data={skills.sequence.map(skillName => skills.index[skillName])}
                          renderItem={({ item }) => (
                            <View style={{ width: narrowMedium ? 200 : 275 }}><SkillSet skill={item} narrow={narrow}/></View>
                          )}
                        />
              </View>
    }
    else {
      list = <View style={styles.card}>
        <FlatList
                  contentContainerStyle={ !narrow ? styles.wideList : {}}
                  horizontal={!narrow}
                  keyExtractor={skill => skill.name}
                  data={skills.sequence.map(skillName => skills.index[skillName])}
                  renderItem={({ item }) => (
                    <View><SkillSet skill={item} narrow={narrow}/></View>
                  )}
                />
      </View>
    }

    // Show our section
    return (
      <View style={{ backgroundColor: this.props.background || "#f4f4f4", textAlign: "center", marginBottom: 20 }}>
        {header}
        <Text style={{ marginTop: 0, marginBottom: 20, fontSize: 16, fontWeight: "400", fontFamily: 'System' }}>{skills.description}</Text>
        {list}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: "white",
    shadowColor: '#000',
    borderRadius:10,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 20,
    elevation: 5,
    paddingTop: 15,
    paddingBottom: 10,
    marginLeft: 20,
    marginRight: 20,
    paddingLeft: 25,
    paddingRight: 35,
  },
  cardContent: {
    width: "100%",
    height: "100%",
  },
  wideList: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: 1200
  },
  mediumList: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    maxWidth: 1200
  },
  mediumItem: {
    width: 250
  }

});
