import React from 'react';
import {
  View,
  Dimensions,
  Platform
} from 'react-native';

import FullScreenBase from './FullScreenBase';

export default class FullScreen extends FullScreenBase {

  // Used to size the screen
  state = {
    width: window.innerWidth,
    height: window.innerHeight
  }

  componentDidMount() {
    this.hearResize();
    this._refreshInterval = setInterval(()=>{
      this.fill();
    },500);
  }

  componentWillUnmount() {
    this.muteResize();
    clearInterval(this._refreshInterval);
  }

  updateDimensions = () => {
    this.fill();
  }

  updateDimensionsSoon = () => {
    setTimeout(() => { this.updateDimensions(); }, 500);
  }

  updateDimensionsNowAndSoon = () => {
    this.updateDimensions();
    setTimeout(() => { this.updateDimensions(); }, 500);
  }

  muteResize() {
    if ( Platform.OS == "web" ) {
      window.removeEventListener("resize", this.updateDimensionsNowAndSoon);
      window.removeEventListener("orientationchange", this.updateDimensions);
    }
    else {
      Dimensions.removeEventListener("change", this.updateDimensionsNowAndSoon);
    }
  }

  hearResize() {
    if ( Platform.OS == "web" ) {
      window.addEventListener("resize", this.updateDimensionsNowAndSoon);
      window.addEventListener("orientationchange", this.updateDimensionsSoon);
    }
    else {
      Dimensions.addEventListener("change", this.updateDimensionsNowAndSoon);
    }
  }

  fill() {
    let dims = { width: window.innerWidth, height: window.innerHeight}
    let zoom = document.documentElement.clientWidth / dims.width;
    if (zoom === 1) {
      let width = this._container.props.style.width;
      let height = this._container.props.style.height
      if (dims.width != width || dims.height != height) {
        this.setState(dims);
      }
    }
  }

  nudge() {
    let width = this.state.width;
    let height = this.state.height;
    this.setState({width:width+1, height:height/2});
  }

  render() {
    return (
      <View ref={(c)=>{this._container=c;}} style={{width:this.state.width, height:this.state.height}}>
          {this.props.children}
      </View>
    )
  }
}
