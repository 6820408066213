// src/index.js
import React from 'react';
import { Platform } from 'react-native';
import ReactDom from 'react-dom';
import App from './App';

// Import special styles for the web version
import './index.css';

// Scroll animation polyfill
import smoothscroll from 'smoothscroll-polyfill';

// kick off the polyfill only for web!
if (Platform.OS === 'web') {
  smoothscroll.polyfill();
}


ReactDom.render(<App />, document.getElementById("root"));
