export default {
  story: {
    illustration: {
      image: "textblade",
      side: "right",
      width: 450,
      height: 450,
    },
    paragraphs: [
      "We began our journey in Santa Monica, creating an affordable and high accuracy 3D scanner that quickly became the world standard.",
      "Hooked on creating game changing inventions, we turned to the burgeoning mobile revolution.  We invented the magnetic and magical TextBlade, the world's smallest, most comfortable, and most efficient keyboard.",
      "From there, we parachuted into different parts of the industry.  Some of us teamed up to found new startups.  Others dived into challenging problems at big tech.",
      "Now the band is back together, and we're combining our talents to create novel experiences and technology.",
    ],
  },
  approach: {
    illustration: {
      image: "keyswitch",
      side: "left",
      width: 425,
      height: 425,
    },
    paragraphs: [
      "Design thinking can transform challenging problems into amazing new opportunities.",
      /*We love being the first to solve brave new problems. */
      "Our team's process marries the analytical with the aesthetic, and the intellect with inspiration, to elegantly solve problems that were previously intractable.",
      "We believe that power and simplicity are not mutually exclusive. Thoughtful design can deliver sophisticated capabilities in an experience that feels simple and joyful.",
      "We drive designs forward through a combination of deep thought experiments, exciting visual mockups, usable rapid prototypes, and user testing.",
      /*"We love being the first to solve brave new problems.  We study those that have come before, and steer the ship deeper.",*/
      /*"When design goals and benefits are precisely articulated, they become easy to evaluate objectively. ",*/
      /*"We believe that design is a practice that works best when all ideas are welcome, and all viewpoints are honored.  Insight can come from anywhere, and every single person, situation, and interaction can be a powerful teacher – if we listen.",*/
      /*When design goals and benefits are precisely articulated, they become easy to evaluate objectively.  */
    ],
  },
};

/* Einstein was a big believer in the power of the thought experiment.*/
