import { CHOOSE_CATEGORY,
         CHOOSE_CARD,
         CLOSE_CARD,
         CHOOSE_MENU,
         ENABLE_MENU,
         DISABLE_MENU,
         OPEN_HAMBURGER,
         CLOSE_HAMBURGER,
       } from 'state/actions/actionTypes.js'

// Update the menu
const menu = (state = { name: "top" }, action) => {
  switch(action.type) {
    case CHOOSE_MENU:
      if (state.disabled === true) {
        return state;
      }
      return { ...action.menu }
    case ENABLE_MENU:
    case DISABLE_MENU:
      return { ...action.menu }
    default:
      return state;
  }
}

// Update the category
const category = (state = undefined, action) => {
  switch(action.type) {
    case CHOOSE_CATEGORY:
      return action.category;
    default:
      return state;
  }
}

// Update the card
const card = (state = {}, action) => {
  switch(action.type) {
    case CHOOSE_CARD:
      return { ...action.card, active: true }
    case CLOSE_CARD:
      return { ...state, active: false }
    default:
      return state;
  }
}

// Show or hide the hamburger menu
const hamburger = (state = {}, action) => {
  switch(action.type) {
    case OPEN_HAMBURGER:
      return { ...action.hamburger, active: true }
    case CLOSE_HAMBURGER:
      return { ...state, active: false }
    default:
      return state;
  }
}

// This is the main reducer for our app
export const reducer = (state = {}, action) => {
  //console.log("ACTION:")
  //console.log(action)
  const newState = {
    menu: menu(state.menu, action),
    hamburger: hamburger(state.hamburger, action),
    category: category(state.category, action),
    card: card(state.card, action),
  };
  //console.log("NEW STATE:")
  //console.log(newState);
  return newState;
}
