// src/Home.js
import React from 'react';
import {
  View,
  Text,
  Easing,
  TouchableWithoutFeedback,
  Animated
} from 'react-native';

class OutlineButton extends React.Component {

  // Used for animations on press
  state = {
    pressed: false,
    scale: new Animated.Value(1),
  }

  handlePress() {
    if ( this.props.onPress ) {
      this.props.onPress();
    }
  }

  render() {

    return (
      <TouchableWithoutFeedback style={{ flex: 1, }}
             onPressIn={ () => {
                //if ( Platform.OS === 'ios' ) { Haptic.selection(); }
                //Haptic.impact(Haptic.ImpactStyles.Light);
                this.setState({ pressed: true });
                Animated.timing(                  // Animate over time
                     this.state.scale,            // The animated value to drive
                     {
                       toValue: 0.98,               // Animate to size 18
                       duration: 50,              // Make it take a while
                     }
                   ).start();
              }}
              onPressOut= { () => {
                //if ( Platform.OS === 'ios' ) { Haptic.impact(Haptic.ImpactFeedbackStyle.Light); }
                this.setState({ pressed: false });
                Animated.timing(                   // Animate over time
                     this.state.scale,              // The animated value to drive
                     {
                       toValue: 1,                 // Animate back to small size
                       duration: 500,              // Make it take a while
                       easing: Easing.back(),
                     }
                   ).start();
              }}
              onPress={ () => { this.handlePress(); } }>
        <Animated.View style={{ backgroundColor: "rgba(0,0,0,0)",
                       width: this.props.width,
                       height: this.props.height || 50,
                       border: this.props.border,
                       borderRadius: 5,
                       borderColor: this.props.color || "black",
                       borderWidth: 1,
                       flex: 1,
                       transform: [{ scale: this.state.scale}] }}>
          <Text style={{
            color: this.props.color || "black",
            fontSize: this.props.fontSize || 16,
            fontWeight: "500",
            fontFamily: "System",
            lineHeight: 50,
            marginLeft: 15,
            marginRight: 15,
          }}>{this.props.text}</Text>
        </Animated.View>
      </TouchableWithoutFeedback>
    );
  }
};
export default OutlineButton;
