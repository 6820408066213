//https://res.cloudinary.com/higher-design/image/upload/v1552703321/people/pete.jpg
import React from 'react';
import {
  View,
  Image,
  Text,
} from 'react-native';
import HigherGradient from 'components/utility/HigherGradient'
import PEOPLE from 'constants/people';

export default class PersonSection extends React.Component {
  render() {

    // Lookup the person
    const person = PEOPLE.index[this.props.person] || {};

    // This is the image for the person
    const personImage = `https://res.cloudinary.com/higher-design/image/upload/r_max,h_800,w_800/v1551630385/people/${person.image}.jpg`;

    // Show an optional description
    let description = <View/>;
    if ( this.props.showDescription ) {
      description = <Text style={{
                                   flex: 1,
                                   alignSelf: "center",
                                   marginTop: 0,
                                   marginBottom: 15,
                                   textAlign: "left",
                                   fontSize: 16,
                                   fontWeight: "400",
                                   fontFamily: 'System' }}>{person.description}</Text>
    }

    // Titles
    let titles = <View>
      <Text style={{ marginTop: 15, fontSize: 28, fontWeight: "800", fontFamily: 'System' }}>{person.firstName.toUpperCase()}</Text>
      <Text style={{ marginTop: 3, marginBottom: 20, fontSize: 17, fontWeight: "800", fontFamily: 'System' }}>{person.lastName.toUpperCase()}</Text>
    </View>;

    titles = <View>
        <Text style={{ marginTop: 15, fontSize: 28, fontWeight: "800", letterSpacing: 2, fontFamily: 'DINCondensed-Bold,System' }}>{person.firstName.toUpperCase()}</Text>
        <Text style={{ marginTop: 3, marginBottom: 20, fontSize: 17, fontWeight: "800", fontFamily: 'DINCondensed-Bold,System' }}>{person.lastName.toUpperCase()}</Text>
    </View>;

    titles = <View>
        <Text style={{ marginTop: 3, marginBottom: 20, fontSize: 30, fontWeight: "800", fontFamily: 'DINCondensed-Bold,System' }}>{person.firstName.toUpperCase()} {person.lastName.toUpperCase()}</Text>
    </View>;

    titles = <View>
        <Text style={{ marginTop: 18, marginBottom: 15, fontSize: 28, fontWeight: "800", fontFamily: 'System' }}>{person.firstName} {person.lastName}</Text>
    </View>;
    // Show our section
    return (
      <HigherGradient
         direction="to bottom"
         from="#ffffff"
         to="#ffffff">
        <View style={{ paddingTop: 20, textAlign: "center", opacity: this.props.opacity }}>
            <Image style={{ width: 230, height: 230, alignSelf: "center", borderRadius: 160 /*, filter: "grayscale(1.0)" */}}
                   source={{ uri: personImage }}
                   onPress={() => { if ( this.props.onPress ) { this.props.onPress(); }}}/>
            {titles}
            {description}
        </View>
      </HigherGradient>
    )
  }
}
