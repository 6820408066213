// src/Home.js
import React from 'react';
import {
  View,
  Text,
  FlatList,
  Dimensions,
  Platform,
} from 'react-native';
import CategoryItem from 'components/items/CategoryItem';
import CATEGORIES from 'constants/categories';

class CategoryStrip extends React.Component {

  // Track the window size
  state = Dimensions.get("window");

  // Update our dimensions now
  updateDimensions() {
    this.setState(Dimensions.get('window'));
  }

  componentWillMount() {
    if ( Platform.OS == "web" ) {
      window.addEventListener("resize", () => { this.updateDimensions() });
    }
    else {
      Dimensions.addEventListener("change", () => { this.updateDimensions() });
    }
  }

  componentWillUnmount() {
    if ( Platform.OS == "web" ) {
      window.removeEventListener("resize", () => { this.updateDimensions() });
    }
    else {
      Dimensions.removeEventListener("change", () => { this.updateDimensions() });
    }
  }

  render() {

    // Handle category presses
    const onCategoryPress = category => {
      if (this.props.onCategoryPress) {
        this.props.onCategoryPress(category);
      }
    };

    // How much padding to do
    let margins = 2;
    if ( this.state.width > 550 ) {
      margins = 15;
    }

    // Should we show a line?
    let line = <View/>;
    if ( this.props.line ) {
      line = <View style={{ marginTop: 0, height: 1, backgroundColor: "#DBDBDB"}}/>;
    }

    return (
      <View style={{ backgroundColor: this.props.background || "#ffffff", paddingTop: 5, paddingBottom: 0 }}>
        <FlatList
          style={{ textAlign: "center", alignSelf: "center"}}
          horizontal
          keyExtractor={category => category.name}
          data={this.props.categories.map(categoryName => CATEGORIES.index[categoryName])}
          renderItem={({ item }) => (
            <View><CategoryItem margins={margins} category={item} onPress={()=>{ onCategoryPress(item); }}/></View>
          )}
        />
        {line}
      </View>
    );
  }
};
export default CategoryStrip;
