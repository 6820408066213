// src/Home.js
import React from 'react';
import { Animated, Easing, Platform, StyleSheet, Text, Image, View, TouchableWithoutFeedback } from 'react-native';
import { connect } from 'react-redux'
import { chooseCategory } from 'state/actions/categoryActions.js'
import { closeHamburger } from 'state/actions/hamburgerActions.js'
//import { Haptic } from 'expo';

class CategoryItem extends React.Component {

  // Used for animations on press
  state = {
    pressed: false,
    scale: new Animated.Value(1),
    saturation: new Animated.Value(1)
  }

  componentDidMount() {
    if ( !this.props.selectedCategoryName || this.props.selectedCategoryName === this.props.category.name ) {
      this.state.saturation.setValue(1);
    }
    else {
      this.state.saturation.setValue(0);
    }

    /*Animated.timing(                  // Animate over time
         this.state.scale,            // The animated value to drive
         {
           toValue: 1,                // Animate to full color
           duration: 300,             // Make it take a while
         }
       ).start();*/
  }

  // Auto scroll to bottom after extending
  componentDidUpdate(prevProps, prevState) {
    if ( this.props.selectedCategoryName !== prevProps.selectedCategoryName ) {

      // Figure out if we're selected
      const isColorful = !this.props.selectedCategoryName || this.props.selectedCategoryName === this.props.category.name;

      // Are we selected or not selected
      if ( isColorful ) {
        if ( this.props.selectedCategoryName ) {
          Animated.timing(                     // Animate over time
               this.state.saturation,            // The animated value to drive
               {
                 toValue: 1,                   // Animate to full color
                 duration: 300,                // Make it take a while
               }
             ).start();
        }
        else {
          Animated.timing(                     // Animate over time
               this.state.saturation,            // The animated value to drive
               {
                 toValue: 1,                   // Animate to full color
                 duration: 450,                // Make it take a while
                 easing: Easing.back()
               }
             ).start();
        }
      }
      else {
        Animated.timing(                     // Animate over time
             this.state.saturation,            // The animated value to drive
             {
               toValue: 0,                     // Animate to gray
               duration: 300,                // Make it take a while
             }
           ).start();
      }
    }
  }

  handlePress() {
    if ( this.props.category.name === this.props.selectedCategoryName ) {
      this.props.chooseCategory(undefined);

      // Tag whenever a filter is unapplied
      window.gtag('event', "Filter", { 'event_category': "Category", 'event_label': "none" });
    }
    else {
      this.props.chooseCategory(this.props.category.name);

      // Tag whenever a filter is applied
      window.gtag('event', "Filter", { 'event_category': "Category", 'event_label': this.props.category.name });
    }

    // If the hamburger is open, then close it
    if ( this.props.hamburger && this.props.hamburger.active ) {
      setTimeout(() => { this.props.closeHamburger(); }, 300);
    }

    // Call onPress callback
    if (this.props.onPress) {
      this.props.onPress();
    }
  }

  render() {

    // It's gray if there's a selected category and it's not us
    let gray = this.props.selectedCategoryName && this.props.selectedCategoryName != this.props.category.name;

    // Is it colorful or gray
    const imageColor = gray ? "gray" : "color";
    //const textColor = gray ? "#9A9A9A" : "black";

    // Figure out animation sizes
    const minImageWidth = 60; //70 * 0.85;
    const minImageHeight = 42; //50 * 0.85;
    const imageWidth = Animated.multiply(minImageWidth, this.state.scale);
    const imageHeight = Animated.multiply(minImageHeight, this.state.scale);
    const textColor = this.state.saturation.interpolate({ inputRange: [0, 1], outputRange: ["#9A9A9A", "#000000"] });

    // The maximum image scale
    const maxScale = 1.1;

    // How colorful is it
    const grayImage = `https://res.cloudinary.com/higher-design/image/upload/c_mpad,h_256,w_340/v1551630385/categories/gray/${this.props.category.name}.png`;
    const colorImage = `https://res.cloudinary.com/higher-design/image/upload/c_mpad,h_256,w_340/v1551630385/categories/color/${this.props.category.name}.png`;

    // Return it now
    return (
      <View style={{ marginLeft: (this.props.margins || 0) + (this.props.category.marginLeft || this.props.category.margins || 2),
                     marginRight: (this.props.margins || 0) + (this.props.category.marginRight || this.props.category.margins || 2),
                     marginBottom: 10 }}>
      <TouchableWithoutFeedback
        onPressIn={ () => {
          //if ( Platform.OS === 'ios' ) { Haptic.selection(); }
          //Haptic.impact(Haptic.ImpactStyles.Light);
          this.setState({ pressed: true });
          Animated.timing(                  // Animate over time
               this.state.scale,            // The animated value to drive
               {
                 toValue: maxScale,               // Animate to size 18
                 duration: 0,              // Make it take a while
               }
             ).start();
        }}
        onPressOut= { () => {
          //if ( Platform.OS === 'ios' ) { Haptic.impact(Haptic.ImpactFeedbackStyle.Light); }
          this.setState({ pressed: false });
          Animated.timing(                   // Animate over time
               this.state.scale,              // The animated value to drive
               {
                 toValue: 1,                 // Animate back to small size
                 duration: 500,              // Make it take a while
                 easing: Easing.back(),
               }
             ).start();
        }}
        onPress={ () => { this.handlePress(); } }>
          <View>
            <View style={{ width: minImageWidth * maxScale, height: minImageHeight * maxScale, justifyContent: 'flex-end' }}>
              <Animated.View style={{ alignSelf: "center",
                             width: imageWidth,
                             height: imageHeight }}>
                 <Image style={{ position: "absolute",
                                 top: 0,
                                 width: "100%",
                                 height: "100%" }}
                                 source={{ uri: grayImage }} />
                 <Animated.Image style={{ position: "absolute",
                                 top: 0,
                                 width: "100%",
                                 height: "100%",
                                 opacity: this.state.saturation }}
                                 source={{ uri: colorImage }} />
              </Animated.View>

            </View>
            <View style={{ marginTop: 5 }}>
              <Animated.Text style={{ textAlign: "center", color: textColor, fontSize: 15, fontWeight: "400", fontFamily: 'System' }}>{this.props.category.title}</Animated.Text>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </View>
    );
  }
};

const childProps = (state, parentProps) => ({
  ...parentProps,
  selectedCategoryName: state.category,
  hamburger: state.hamburger,
});

const childDispatch = (dispatch) => {
    return {
        chooseCategory: (category) => dispatch(chooseCategory(category)),
        closeHamburger: () => dispatch(closeHamburger()),
    };
};

export default connect(
  childProps,
  childDispatch
)(CategoryItem);
