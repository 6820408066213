import React from 'react';
import { Animated, Easing, View, Text, TouchableWithoutFeedback, TouchableOpacity } from 'react-native';
import CategoryStrip from 'components/strips/CategoryStrip';

/*import { Haptic } from 'expo';*/

export default class CategoriesHeader extends React.Component {

  state = { animatedScroll: new Animated.Value(0) };

  // Set initial state
  componentDidMount() {
    this.state.animatedScroll.setValue(this.props.scroll);
  }

  // Auto scroll to bottom after extending
  componentDidUpdate(prevProps, prevState) {
    const gettingBigger = this.props.scroll > prevProps.scroll;
    const bigJump = this.props.scroll > prevProps.scroll + 20;
    const goneFarEnough = this.props.scroll >= 180;
    const goneTooFar = this.props.scroll >= 220;
  /*  if ( gettingBigger && bigJump && goneFarEnough ) {
      Animated.timing(                     // Animate over time
           this.state.animatedScroll,      // The animated value to drive
           {
             toValue: this.props.scroll,   // Animate to full color
             duration: 500,                // Make it take a while
           }
         ).start();
    }
    else if ( gettingBigger && !bigJump && goneTooFar ) {

    }
    else {
      //this.state.animatedScroll.setValue(this.props.scroll);
    }*/

    this.state.animatedScroll.setValue(this.props.scroll);
  }

  render() {

    // Handle category presses
    const onTitlePress = () => {
      if ( this.props.onTitlePress ) {
        this.props.onTitlePress(this.props.title);
      }
    };

    // Handle category presses
    const onCategoryPress = category => {
      if ( this.props.onCategoryPress ) {
        this.props.onCategoryPress(category);
      }
    };

    // Is there a touch zone
    let titleTouchZone = <View/>;
    if ( this.props.scroll >= 160 ) {
      titleTouchZone = <TouchableOpacity onPress={onTitlePress} style={{ position: "absolute", zIndex: 3, top: 0, left: 0, width: "100%", height: 50 }}/>;
    }

    // Figure out what ranges we're using
    const backgroundScrollRange = this.props.personName ? [175, 250] : [85, 160];
    const headerScrollRange = this.props.personName ? [260, 300] : [160, 180];

    //const animatedScroll = new Animated.Value(this.props.scroll);
    //const backgroundOpacity = this.state.animatedScroll.interpolate({ inputRange: [75, 150], outputRange: [1, 0] });
    //const headerOpacity = this.state.animatedScroll.interpolate({ inputRange: [140, 180], outputRange: [0, 1] });
    const backgroundOpacity = this.state.animatedScroll.interpolate({ inputRange: backgroundScrollRange, outputRange: [1, 0] });
    const headerOpacity = this.state.animatedScroll.interpolate({ inputRange: headerScrollRange, outputRange: [0, 1] });

    return <View style={{ backgroundColor: "#ffffff", width: "100%" }}>
              <Animated.Text style={{
                                      flex: 1,
                                      alignSelf: "center",
                                      marginTop: this.props.personName ? 0 : 16,
                                      marginBottom: 15,
                                      textAlign: "left",
                                      fontSize: 16,
                                      fontWeight: "400",
                                      opacity: backgroundOpacity,
                                      fontFamily: 'System' }}>{this.props.description}</Animated.Text>
              <Animated.Text style={{ opacity: headerOpacity,
                                        position: "absolute",
                                        zIndex: 2,
                                        width: "100%",
                                        top: 0,
                                        left: 0,
                                        paddingTop: 15,
                                        paddingBottom: 15,
                                        textAlign: "center",
                                        fontSize: 28,
                                        fontWeight: "800",
                                        fontFamily: 'System'}}>PORTFOLIO</Animated.Text>
              {titleTouchZone}
              <CategoryStrip line
                           categories={this.props.categories}
                           onCategoryPress={onCategoryPress}/>
            </View>



    if ( this.props.touchable ) {
      return <View style={{ backgroundColor: this.props.background || "#f4f4f4" }}>
                <TouchableOpacity onPress={() => { if ( this.props.onPress ) { this.props.onPress(); } }}>
                  <Text style={{ paddingTop: this.props.paddingTop || 15, paddingBottom: 15, backgroundColor: this.props.background || "#f4f4f4", textAlign: "center", fontSize: 28, fontWeight: "800", fontFamily: 'System'}}>{this.props.title.toUpperCase()}</Text>
                </TouchableOpacity>
                <View style={{ height: 1, width: "100%", backgroundColor: "#DBDBDB", opacity: this.state.opacity }}/>
             </View>;
    }
    else {
      return <View style={{ backgroundColor: this.props.background || "#f4f4f4" }}>
                <Text style={{ paddingTop: this.props.paddingTop || 15, paddingBottom: 15, backgroundColor: this.props.background || "#f4f4f4", textAlign: "center", fontSize: 28, fontWeight: "800", fontFamily: 'System'}}>{this.props.title.toUpperCase()}</Text>
                <View style={{ height: 1, width: "100%", backgroundColor: "#DBDBDB", opacity: this.state.opacity }}/>
             </View>;
    }
  }
}
