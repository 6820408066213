import React from 'react';
import { Text, View } from 'react-native';
import ColorButton from 'components/buttons/ColorButton';
import { connect } from 'react-redux';

class ViewResumeButton extends React.Component {

  onPress() {
    if ( this.props.onPress ) {
      this.props.onPress();
    }
  }

  render() {
    return (
      <ColorButton width={this.props.width}
                   text="See More"
                   backgroundColor={ this.props.backgroundColor || "#00AEEF"}
                   onPress={() => { this.onPress(); } }/>
    );
  }
};

const childProps = (state, parentProps) => ({
  ...parentProps,
});

const childDispatch = (dispatch) => {
    return {
    };
};

export default connect(
  childProps,
  childDispatch
)(ViewResumeButton);
