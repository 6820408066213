export default {
  "portfolio": {
    name: "portfolio",
    image: "personal/portfolio-in-ipad",
    theme: "light",
    aspectRatio: 0.55,
    searchOrder: 3,
    title: "Petefol.io",
    subtitle: "App, Web",
    categories: ["app", "web"],
    description: "This portfolio is 100% custom built.  It's powered by a single React Native codebase that compiles to iOS, Android, and Web, delivering smooth native performance everywhere.",
//render: () => { return <TextBladeProject/> },
    content: [
      { description: true },
      { text: "I've long dreamed of a singular home for all my work, and also of creating an uncompromised yet fully multi-platform experience with a single codebase.  This project fulfills both dreams." },
      { text: "The playful and colorful design helps visitors explore and understand the unique multi-disciplinary nature of my work.  It perfects the card-based user interface that I began exploring with UpGift, and was built from the ground up to support motion." },
      { text: "As motion design has become standard table stakes, the task of the designer and front end engineer has become more mechanical and physical in nature.  We must design and build simulated machines that move and unfold in a realistic way.  Animation and motion are not add-ons.  Rather, they require a rethinking of the very bones and object oriented layout of our front-end code." },
    ],
  },
  "teamfolio": {
    name: "teamfolio",
    image: "personal/portfolio-in-ipad",
    theme: "light",
    aspectRatio: 0.55,
    searchOrder: 1,
    title: "Teamfolio",
    subtitle: "App, Web",
    categories: ["app", "web"],
    description: "This portfolio is 100% custom built.  It's powered by a single React Native codebase that compiles to iOS, Android, and Web, delivering smooth native performance everywhere.",
//render: () => { return <TextBladeProject/> },
    content: [
      { description: true },
      { text: "The playful and colorful design helps visitors explore and understand the unique multi-disciplinary nature of our work.  It perfects the card-based user interface explored with UpGift, and was built from the ground up to support motion." },
      { text: "As motion design has become standard table stakes, the task of the designer and front end engineer has become more mechanical and physical in nature.  We must design and build simulated machines that move and unfold in a realistic way.  Animation and motion are not add-ons.  Rather, they require a rethinking of the very bones and object oriented layout of our front-end code." },
    ],
  },

};
