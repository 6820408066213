/**
 * This will be shallow merged with main people/[name].js
 * Put new values or values that you want to be overwritten in here.
**/
export default {
  title: "petedelaurentis.com",
  contact: {
    email: "pete.delaurentis@gmail.com",
    phone: "415.810.0415",
    web: "petedelaurentis.com",
    street: "1105 Capuchino Ave, Unit 2",
    city: "Burlingame, CA 94010"
  },
  jobs: {
    description: "Designer, engineer, and leader.",
    index: {
      upgift: {
        name: "upgift",
        where: "UpGift",
        when: "2017 - PRESENT",
        project: "UpGift Experience Platform",
        role: "Co-Founder, VP Design + Engineering",
        narrowRole: "VP Design + Engineering",
        description: "Consumer app transforms how we give to unlock the experience economy.",
        bullets: [
          [
             "Led design & development of UpGift app and website.",
             "Designed a UX instantly usable by 3 generations: child, parent, grandparent.",
             "Designed video ads resulting in a 45% conversion rate to website visitors",
             "Designed product branding, icons, social media content, and marketing campaigns.",
          ],
          [
            "Designed a simple, visual, novel user interface for creating bucket lists.",
            "Designed a new tight social network for family members and close friends.",
            "Provided new financial access to kids for classes and learning opportunities.",
          ],
          [
            "Architected and developed universal React app for iOS, Android, and web.",
            "Created a group payment platform supporting Stripe, Apple Pay, Google Pay, Venmo.",
            "Created local experiences search leveraging Yelp, EventBrite, local vendors.",
          ],
        ],
      },
      waytools: {
        name: "waytools",
        where: "WayTools",
        when: "2011 - 2017",
        project: "TextBlade Multi-Touch Keyboard",
        role: "Design + Engineering Lead",
        narrowRole: "Design + Engineering Lead",
        description: "Full QWERTY keyboard, packs to 1/3rd size of iPhone.  Magnetic suspension +  luxurious key feel.",
        bullets: [
          [
            "Designed TextBlade user experience, including patented Home Row Cursor, MultiLayer Keys.",
            "Designed 12 variations of TextBlade layout to support international customers.",
            "Member of 5 person team responsible for TextBlade industrial design, color, layout, ergonomics.",
            "Worked with mechanical, electrical, firmware, app, server engineers to refine + implement designs.",
          ],
          [
            "Led design & development of customer facing TextBlade iOS app.",
            "Designed + led development of company website, order system, factory software, cloud systems.",
            "Scripted, filmed, and edited video media which resulted in $ millions in product sales.",
            "Designed all company and product branding, logos, icons.",
          ],
          [
            "Generated multiple patents to protect our inventions + create a strong intellectual property position.",
            "Led development of advanced diagnostic software, including remote oscilliscope + flight recorder.",
            "Designed fault-tolerant, encrypted OTA protocol and managed weekly OTA updates to customers.",
            "Designed automated QA robot + an iOS app to operate a fleet of QA robots.",
          ],
        ],
      },
      nextengine: {
        name: "nextengine",
        where: "NextEngine",
        when: "2002 - 2011",
        project: "NextEngine Desktop 3D Scanner",
        role: "Design + Engineering Manager",
        narrowRole: "Design + Engineering Manager",
        description: "Best-selling 3D scanner in world. Used in 90+ countries for science, manufacturing, education, film.",
        bullets: [
          [
            "Oversaw design + development of consumer apps, website, order system, all internal software.",
            "Managed talented team of SW engineers, artists.  Worked closely with CEO, marketing team.",
            "Led design, rapid prototyping, and development on projects for key NextEngine partners.",
            /*"Worked with Fujitsu to design a new ultra-high dynamic range image sensor for our 3D scanner.",*/
          ],
          [
            "Led design & development of ScanStudio software for Desktop 3D Scanner (iPhoto for 3D scans).",
            "Designed tools for assembling, editing, and measuring high density 3D models.",
            "Created an integrated customer support system with 3D scan collaboration tools.",
          ],
          [
            "Designed high performance OpenGL rendering engine for massive point clouds.",
            "Architected cloud systems for sharing 3D data, SW licensing, logging, and OTA updates.",
            "Generated multiple patents, including technologies now used in the latest smartphone cameras.",
          ],
        ],
      },
      connectiv: {
        name: "connectiv",
        where: "Connectiv",
        when: "2001 - 2002",
        project: "Fusion Distributed Database",
        role: "Founder + CTO",
        narrowRole: "Founder + CTO",
        bullets: [
          [
            "Designed distributed relational + transactional meta-database, scalable using commodity HW.",
            "Built cross-platform web-app for mapping existing company databases to new system.",
            "Engineered SQL driver for backwards compatibility with existing customer apps.",
          ],
        ],
      },
      usc: {
        name: "usc",
        type: "education",
        where: "Education",
        when: "1997 - 2001",
        project: "University of Southern California ",
        role: "B.S. Computer Science",
        narrowRole: "BS Computer Science + 3 Internships",
        bullets: [
          [
            "Managed USC School of Engineering website",
            "Internships at MapQuest, Identicard, Synerdyne",
            "Internship at Chili!soft (now owned by Sun). Contributed to development platform that sold for $70M.",
          ],
          [
            "Created Democracy Drive, a community cloud drive powered by fault-tolerant network of distributed PCs.",
            "Designed AI programming language + byte-code compiler to enable genetic mutation / recombination.",
            "Programming instructor for at-risk youth in community program.",
          ],
        ],
      },
    },
    sequence: ["upgift", "waytools", "nextengine", "connectiv", "usc"]
  },
  skills: {
    description: "Forever learning.  18 years experience.",
    index: {
      design: {
        name: "design",
        title: "Design",
        bullets: [
          "Product Design",
          "UX / UI Design",
          "Motion Design",
          "User Research",
          "Marketing",
          "Branding",
          "Industrial Design",
          "Video Production",
          "Rapid Prototyping",
          "System Architecture",
        ],
      },
      tools: {
        name: "tools",
        title: "Tools",
        bullets: [
          "Sketch",
          "InVision",
          "Adobe XD",
          "Photoshop",
          "Illustrator",
          "After Effects",
          "Premiere",
          "Final Cut X",
          "SolidWorks CAD",
          "Modo 3D Rendering",
        ],
      },
      code: {
        name: "code",
        title: "Code",
        bullets: [
          "React",
          "React Native",
          "Objective-C",
          "HTML / CSS",
          "JavaScript",
          "Node.js",
          "Ruby",
          "Java",
          "C++",
          "C",
        ],
      },
      lead: {
        name: "lead",
        title: "Leadership",
        bullets: [
          "Humility",
          "Empathy",
          "Love of Learning",
          "Boundless Curiosity",
          "Consensus Building",
          "Active Listening",
          "Finding the Why",
          "Creating a Vision",
          "Product Management",
          "Calm under Fire",
        ],
      },
    },
    sequence: ["design", "tools", "code", "lead"],
  },
};
