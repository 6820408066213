// src/App.js
import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import { Router, Switch, Route } from './routing';

// Redux
import thunk from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { reducer } from 'state/reducers/rootReducer.js'
import { initialState } from 'state/initial.js'

// Domain settings
import { DOMAIN } from 'lib/Domain';

// UI
import HomePage from 'components/pages/HomePage';
import PersonPage from 'components/pages/PersonPage';

// Define our store around our reducer, initial state, and middleware
const store = createStore(reducer, initialState, applyMiddleware(thunk));

// Define our app now
class App extends Component {
  state = {
  };
  render() {

    // Check if domain defines it's own routes
    if (DOMAIN.routes && DOMAIN.routes.length > 0) {
      return (<Provider store={store}>
        <View style={styles.container}>
          <Router>
            <Switch>
              {DOMAIN.routes.map((route) => {
                // TODO - Fernando Mora: Add support for other render types
                switch (route.render.type) {
                  case "PersonPage":
                    return (<Route
                      exact={route.exact}
                      path={route.path}
                      render={props => (
                        <PersonPage {...props} {...route.render.props}/>
                      )}
                    />)
                  default:
                    return (<Route
                      exact
                      path={route.path}
                      render={props => (
                        <HomePage {...props}/>
                      )}
                    />)
                }
              })}
            </Switch>
          </Router>
        </View>
      </Provider>)
    }

    // This is the default main rendering
    return (
      <Provider store={store}>
        <View style={styles.container}>
          <Router>
            <Switch>
              <Route
                exact
                path="/"
                render={props => (
                  <HomePage {...props}/>
                )}
              />
              <Route
                exact
                path="/pete"
                render={props => (
                  <PersonPage {...props} personName="pete"/>
                )}
              />
              <Route
                exact
                path="/fernando"
                render={props => (
                  <PersonPage {...props} personName="fernando"/>
                )}
              />
              <Route
                exact
                path="/ronny"
                render={props => (
                  <PersonPage {...props} personName="ronny"/>
                )}
              />
              <Route
                path="/project/:projectName"
                render={props => (
                  <HomePage {...props} selectedProjectName={props.match.params.projectName}/>
                )}
              />
            </Switch>
          </Router>
        </View>
      </Provider>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F4F4F4',
  }
});

export default App;
