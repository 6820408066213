//https://res.cloudinary.com/higher-design/image/upload/v1552703321/people/pete.jpg
import React from 'react';
import {
  View,
  Text,
  Platform,
  StyleSheet,
} from 'react-native';
import HigherGradient from 'components/utility/HigherGradient';
import ProjectCardStrip from 'components/strips/ProjectCardStrip';
import PROJECTS from 'constants/projects';

export default class ProjectSection extends React.Component {

  // Convert an array of names into an array of projects
  projectsByName(names) {
    return names.map(projectName => PROJECTS.index[projectName]);
  }

  render() {

    // Which rows of cards to show
    let rows = [];

    // Is it filtered by category?
    if ( this.props.selectedCategoryName ) {

      // Produce a merged single row version
      const merged = [].concat.apply([], this.props.projects);

      // Get the actual details, not just the name
      const cards = this.projectsByName(merged);

      // Now filter it down
      const filtered = cards.filter( card => {

        // First make sure there is a card
        if ( card ) {

          // Combine the main categories with any extra search categories
          const mainCategories = card.categories || [];
          const combinedCategories = card.extraSearchCategories ? mainCategories.concat(card.extraSearchCategories) : mainCategories;

          // Return if it's included
          return combinedCategories.includes(this.props.selectedCategoryName);
        }
        else {
          return false;
        }
      });

      // Sort it now
      const sorted = filtered.sort( (a, b) => {

        // Get both search orders
        const aSearchOrder = a.searchOrder || 100;
        const bSearchOrder = b.searchOrder || 100;

        // Compare these two
        return aSearchOrder - bSearchOrder;
      });

      // Now make a row for the sorted entries
      rows = [sorted];
    }
    else {

      // Just make the cards more
      rows = this.props.projects.map(row => this.projectsByName(row));
    }

    // Optional line
    let line = <View/>;
    if ( this.props.line ) {
      line = <View style={{ height: 1, marginTop: 0, marginBottom: 0, backgroundColor: "#DBDBDB"}}/>;
    }

    // Return our set of projects
    return <View style={{ width: "100%", backgroundColor: "#f4f4f4" }}>
              <HigherGradient style={{ height: 40, marginBottom: -30 }}
                              direction="to bottom"
                              from={this.props.fadeColor || "#f4f4f4"}
                              to="#f4f4f4"/>
              {rows.map((row, index) => <ProjectCardStrip key={`row${index}`}
                                                          cards={row}
                                                          mobile={this.props.mobile}
                                                          selectedCategoryName={this.props.selectedCategoryName}
                                                          personName={this.props.personName}/>)}
              <HigherGradient style={{ height: 40, marginTop: -28, zIndex: -1 }}
                              direction="to bottom"
                              from="#f4f4f4"
                              to={this.props.fadeColor || "#f4f4f4"} />
              {line}
           </View>;
  }
}
