//https://res.cloudinary.com/higher-design/image/upload/v1552703321/people/pete.jpg
import React from 'react';
import {
  View,
  Text,
  FlatList,
  Dimensions,
  Platform,
  Image,
  StyleSheet,
} from 'react-native';
import HigherGradient from 'components/utility/HigherGradient'

//import { LinearGradient } from 'expo';

import PEOPLE from 'constants/people';

export default class TextSection extends React.Component {

  // Track the window size
  state = Dimensions.get("window");

  onJobPress(job) {
  }

  // Update our dimensions now
  updateDimensions() {
    this.setState(Dimensions.get('window'));
  }

  componentWillMount() {
    if ( Platform.OS == "web" ) {
      window.addEventListener("resize", () => { this.updateDimensions() });
    }
    else {
      Dimensions.addEventListener("change", () => { this.updateDimensions() });
    }
  }

  componentWillUnmount() {
    if ( Platform.OS == "web" ) {
      window.removeEventListener("resize", () => { this.updateDimensions() });
    }
    else {
      Dimensions.removeEventListener("change", () => { this.updateDimensions() });
    }
  }

  render() {

    // Lookup the person
    const person = PEOPLE.index[this.props.person] || {};
    const about = person.about || {};

    // Is it the narrow version
    const narrow = this.state.width < 950;

    // Show a header?
    let header = <View/>;
    if ( this.props.showHeader ) {
      header = <Text style={{ paddingTop: 15, paddingBottom: 15, textAlign: "center", fontSize: 28, fontWeight: "800", fontFamily: 'System'}}>{this.props.title.toUpperCase()}</Text>;
    }

    // Optional line
    let line = <View/>;
    if ( this.props.line ) {
      line = <View style={{ height: 1, marginTop: 10, backgroundColor: "#DBDBDB"}}/>;
    }

    // Is there an illustration on the left or right
    let illustrationOnLeft = <View/>;
    let illustrationOnRight = <View/>;
    if ( this.props.illustration && !narrow ) {

      // Get the image we're going to use
      const illustrationImage = <Image style={{ width: this.props.illustration.width || 400,
                                                height: this.props.illustration.height || 400 }}
                                       source={{ uri: `https://res.cloudinary.com/higher-design/image/upload/c_mpad,h_1024,w_1024/illustrations/${this.props.illustration.image}.jpg` }} />

      // Is it on the left or right side?
      if ( this.props.illustration.side == "left") {
        illustrationOnLeft = <View style={styles.illustrationOnLeft}>{illustrationImage}</View>;
      }
      else if ( this.props.illustration.side == "right") {
        illustrationOnRight = <View style={styles.illustrationOnRight}>{illustrationImage}</View>;
      }
    }

    // Show our section
    return (
      <View style={{ textAlign: "center", paddingBottom: 10, backgroundColor: "#ffffff", width: "100%"}}>
        <HigherGradient
           direction="to bottom"
           from="#ffffff"
           to="#ffffff">
           <View style={styles.textAndIllustration}>
            {illustrationOnLeft}
            <View style={styles.text}>
              {header}
              <View style={styles.description}>
                {this.props.paragraphs.map((paragraph, index) => {
                  return <Text key={index} style={styles.paragraph}>{paragraph}</Text>
                })}
              </View>
            </View>
            {illustrationOnRight}
          </View>
          {line}
        </HigherGradient>
      </View>
    )

    /*        <View style={styles.card}>
            </View>
*/
  }
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: "white",
    shadowColor: '#000',
    borderRadius:10,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 20,
    elevation: 5,
    paddingTop: 15,
    paddingBottom: 15,
    marginLeft: 20,
    marginRight: 20,
    paddingLeft: 25,
    paddingRight: 35,
  },
  cardContent: {
    width: "100%",
    height: "100%",
  },
  description: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 20,
    marginRight: 20,
    maxWidth: 310,
    textAlign: "left",
    alignSelf: "center",
  },
  paragraph: {
    fontSize: 16,
    fontWeight: "400",
    fontFamily: 'System',
    marginBottom: 15,
  },
  textAndIllustration:{
    display: "flex",
    flexDirection: "row",
    alignSelf: "center",
  },
  text:{
  },
  illustrationOnLeft:{
    alignSelf: "flex-end",
    marginLeft: 20,
    marginRight: 20,
  },
  illustrationOnRight:{
    alignSelf: "flex-start",
    marginLeft: 20,
    marginRight: 20,
  },
});
