// src/Home.js
import React from 'react';
import { Animated, Easing, Platform, StyleSheet, Text, Image, View, TouchableWithoutFeedback } from 'react-native';
import { connect } from 'react-redux'
import { chooseCategory } from 'state/actions/categoryActions.js'
//import { Haptic } from 'expo';

export default class PersonItem extends React.Component {

  // Used for animations on press
  state = {
    pressed: false,
    scale: new Animated.Value(1)
  }

  componentDidMount() {
  }

  // Auto scroll to bottom after extending
  componentDidUpdate(prevProps, prevState) {
  }

  handlePress() {
    if ( this.props.onPersonPress ) {
      this.props.onPersonPress(this.props.person);
    }
  }

  render() {

    // Figure out animation sizes
    const minImageWidth = 55; //70 * 0.85;
    const minImageHeight = 55; //50 * 0.85;
    const imageWidth = Animated.multiply(minImageWidth, this.state.scale);
    const imageHeight = Animated.multiply(minImageHeight, this.state.scale);

    // The maximum image scale
    const maxScale = 1.1;

    // How colorful is it
    const personImage = `https://res.cloudinary.com/higher-design/image/upload/r_max,h_256,w_256/v1551630385/people/${this.props.person.image}.jpg`;

    // Return it now
    return (
      <View style={{ marginLeft: (this.props.margins || 0) + (this.props.person.marginLeft || this.props.person.margins || 2),
                     marginRight: (this.props.margins || 0) + (this.props.person.marginRight || this.props.person.margins || 2),
                     marginBottom: 10 }}>
      <TouchableWithoutFeedback
        onPressIn={ () => {
          //if ( Platform.OS === 'ios' ) { Haptic.selection(); }
          //Haptic.impact(Haptic.ImpactStyles.Light);
          this.setState({ pressed: true });
          Animated.timing(                  // Animate over time
               this.state.scale,            // The animated value to drive
               {
                 toValue: maxScale,               // Animate to size 18
                 duration: 0,              // Make it take a while
               }
             ).start();
        }}
        onPressOut= { () => {
          //if ( Platform.OS === 'ios' ) { Haptic.impact(Haptic.ImpactFeedbackStyle.Light); }
          this.setState({ pressed: false });
          Animated.timing(                   // Animate over time
               this.state.scale,              // The animated value to drive
               {
                 toValue: 1,                 // Animate back to small size
                 duration: 500,              // Make it take a while
                 easing: Easing.back(),
               }
             ).start();
        }}
        onPress={ () => { this.handlePress(); } }>
          <View style={{ marginLeft: 10, marginRight: 10 }}>
            <View style={{ width: minImageWidth * maxScale, height: minImageHeight * maxScale, justifyContent: 'flex-end' }}>
              <Animated.View style={{ alignSelf: "center",
                             width: imageWidth,
                             height: imageHeight }}>
                 <Image style={{ position: "absolute",
                                 top: 0,
                                 width: "100%",
                                 height: "100%",
                                 borderRadius: 100 }}
                                 source={{ uri: personImage }} />
              </Animated.View>

            </View>
            <View style={{ marginTop: 5 }}>
              <Text style={{ textAlign: "center",
                             color: "black",
                             fontSize: 15,
                             fontWeight: "400",
                             fontFamily: 'System' }}>{this.props.person.firstName}</Text>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </View>
    );
  }
};
